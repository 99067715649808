.wrapper {
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.modal {
  border: 10px solid rgb(202, 71, 71);
  background-color: rgb(194, 194, 194);
  box-shadow: 10px 20px 10px rgb(73, 72, 72);
  border-radius: 20px;
  width: 50%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.p {
  padding: 0 10%;
  text-align: center;

  font-weight: bold;
  line-height: 2em;
}

.link {
  border: 3px solid rgb(167, 0, 0);
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  color: black;
  text-decoration: none;
}

.size {
  font-size: 3em;
  color: black;
}
