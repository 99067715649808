.headerH1 {
  color: rgb(114, 114, 253);
  margin-top: 5%;
  font-weight: bold;
  text-align: center;
  font-family: "Press Start 2P";
}

.imagesHolder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow-x: hidden;
  padding: 5% 0;
  gap: 3%;
}

.imageHolder {
  padding: 2.25%;
  margin-bottom: 5%;
  box-sizing: border-box;
  border: 2px solid rgb(114, 114, 253);
  border-radius: 2%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  width: 20%;
}

.imageLabel {
  font-size: 2vw;

  padding: 0 0 3%;
  text-align: center;
  color: white;
}

.img {
  height: 100%;
  max-width: 100%;
  object-fit: contain;
  border-radius: 2%;
  /* overflow: auto; */
  flex: 1;
}
